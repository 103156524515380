import React, { useState, useRef } from 'react';
import { db, storage } from './firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import axios from 'axios';

const CardScanner = () => {
  const [imageUrl, setImageUrl] = useState('');
  const [parsedData, setParsedData] = useState(null);
  const [note, setNote] = useState('');
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cameraFileName] = useState('Take a Picture');
  const [showAlert, setShowAlert] = useState(false);
  const [showInstruction, setShowInstruction] = useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const fileInputRef = useRef(null);

  const handleImageCapture = async (e) => {
    console.log('handleImageCapture triggered');
    const file = e.target.files[0];
    if (file) {
      setUploading(true);
      const timestamp = new Date().getTime();
      const fileName = `${timestamp}_${file.name}`;
      const storageRef = ref(storage, `imagesgroup/${fileName}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      setImageUrl(url);
      setUploading(false);
      setShowInstruction(false);
      console.log('Image uploaded and URL obtained:', url);
      handleTextExtraction(url);
    }
  };

  const handleTextExtraction = async (imageUrl) => {
    console.log('handleTextExtraction triggered');
    if (imageUrl) {
      setLoading(true);
      parseTextWithGPT(imageUrl);
    } else {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);
      console.log('Image URL is not available.');
    }
  };

  const parseTextWithGPT = async (imageUrl) => {
    try {
      console.log('Sending image URL to server:', imageUrl);
      const response = await axios.post('https://scanapi-production.up.railway.app/parse', { imageUrl });
      const parsedData = response.data.parsedData;
      console.log('Parsed data received:', parsedData);
      const jsonData = convertToJSON(parsedData);
      setParsedData(jsonData);
      setLoading(false);
    } catch (error) {
      console.error('Error parsing text with GPT:', error);
      setLoading(false);
    }
  };

  const convertToJSON = (data) => {
    const lines = data.split('\n');
    const dataDict = {};
    lines.forEach(line => {
      const [key, value] = line.split(': ', 2);
      if (key && value) {
        dataDict[key.trim()] = value.trim();
      }
    });
    return dataDict;
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleConfirmUpload = async () => {
    if (parsedData) {
      const dataWithNote = { ...parsedData, note };
      await saveDataToFirebase(dataWithNote);
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        resetForm();
      }, 3000); // Show success message for 3 seconds before resetting the form
    }
  };

  const saveDataToFirebase = async (data) => {
    try {
      await addDoc(collection(db, 'businessCardsnote'), {
        ...data,
        timestamp: serverTimestamp(),
      });
      console.log('Document successfully written!');
    } catch (error) {
      console.error('Error writing document:', error);
    }
  };

  const resetForm = () => {
    setImageUrl('');
    setParsedData(null);
    setNote('');
    setShowInstruction(true);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  return (
    <div>
      {showAlert && <div className="alert">Please tap the photo button to automatically capture the business card information.</div>}
      {showInstruction && <div className="initial-instruction">Please tap the photo button to automatically capture the business card information.</div>}
      <input
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handleImageCapture}
        disabled={uploading || loading}
        style={{ display: 'none' }}
        id="cameraInput"
        ref={fileInputRef}
      />
      <label htmlFor="cameraInput" className="custom-file-input">
        {cameraFileName}
      </label>
      {uploading && <div className="uploading-overlay">Uploading...</div>}
      {loading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <span className="dot-flashing"></span>
            <span>Processing...</span>
          </div>
        </div>
      )}
      {showSuccessMessage && (
        <div className="success-overlay">
          <div className="success-content">
            <span>Upload Successful!</span>
          </div>
        </div>
      )}
      <div>
        {imageUrl && (
          <div>
            <h3>Uploaded Image:</h3>
            <img src={imageUrl} alt="Uploaded business card" style={{ maxWidth: '35%' }} />
          </div>
        )}
        {parsedData && (
          <div>
            <h3>Parsed Data:</h3>
            <pre>{JSON.stringify(parsedData, null, 2)}</pre>
            <div>
              <textarea
                placeholder="Add a note..."
                value={note}
                onChange={handleNoteChange}
                rows="4"
                cols="50"
              />
            </div>
            <button onClick={handleConfirmUpload} className="upload-button">Confirm Upload</button>
          </div>
        )}
      </div>
      <style jsx>{`
        .uploading-overlay, .loading-overlay, .success-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          z-index: 1000;
        }

        .loading-content, .success-content {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .dot-flashing {
          position: relative;
          width: 1em;
          height: 1em;
          margin: 0.5em 0;
          border-radius: 50%;
          background: white;
          color: white;
          animation: dotFlashing 1s infinite linear alternate;
          animation-delay: 0.5s;
        }
        
        .dot-flashing::before, .dot-flashing::after {
          content: '';
          display: inline-block;
          position: absolute;
          top: 0;
        }
        
        .dot-flashing::before {
          left: -1.5em;
          width: 1em;
          height: 1em;
          border-radius: 50%;
          background: white;
          color: white;
          animation: dotFlashing 1s infinite alternate;
          animation-delay: 0s;
        }
        
        .dot-flashing::after {
          left: 1.5em;
          width: 1em;
          height: 1em;
          border-radius: 50%;
          background: white;
          color: white;
          animation: dotFlashing 1s infinite alternate;
          animation-delay: 1s;
        }

        @keyframes dotFlashing {
          0% {
            background-color: white;
          }
          50%,
          100% {
            background-color: rgba(255, 255, 255, 0.2);
          }
        }

        .initial-instruction {
          margin: 20px 0;
          font-size: 24px;
          font-weight: bold;
          color: #333;
        }

        .custom-file-input {
          display: inline-block;
          padding: 12px 24px;
          font-size: 18px;
          cursor: pointer;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          margin-top: 10px;
          margin-right: 10px;
        }

        .custom-file-input:hover {
          background-color: #0056b3;
        }

        .upload-button {
          display: inline-block;
          padding: 12px 24px;
          font-size: 18px;
          cursor: pointer;
          background-color: #28a745;
          color: white;
          border: none;
          border-radius: 4px;
          margin-top: 10px;
        }

        .upload-button:hover {
          background-color: #218838;
        }

        .success-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          z-index: 1000;
        }

        .success-content {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      `}</style>
    </div>
  );
};

export default CardScanner;
